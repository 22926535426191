import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SessionDetailView, getSessionDetails, updateSessionName } from '../SvcService';
import { Box, Button, Card, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { ExportSessionItemViewToCsv } from '../ExportToCsv';
import SessionNameComponent from '../Components/SessionNameComponent';
import SessionGroupComponent from '../Components/SessionGroupComponent';
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
type LogProps = {};

const Log: React.FC<LogProps> = () => {
    const { sessionId: rawSessionId } = useParams<{ sessionId: string }>();
    const sessionId = rawSessionId ?? null;
    const [session, setSession] = useState<SessionDetailView | null>(null);
    const [sessionName, setSessionName] = useState<string>('');
    const [isEditingSessionName, setIsEditingSessionName] = useState(false);
    const [failedItems, setFailedItems] = useState<Record<string, boolean>>({});
    useEffect(() => {
        if (sessionId) {
            getSessionDetails(sessionId).then(data => {
                console.log('Session Data:', data);
                setSession(data);
                setSessionName(data.name);
            });
        }
    }, [sessionId]);

    const handleSaveSessionName = async () => {
        if (sessionId) {
            await updateSessionName(sessionId, sessionName);
            setIsEditingSessionName(false);
        }
    };
    const handleEditSessionName = () => {
        setIsEditingSessionName(true);
    };
    const handlePlayAudio = (itemId: string, audioUrl: string) => {
        const audio = new Audio(audioUrl);
        audio.play().catch((error) => {
          console.error("Failed to play audio:", error);
          // Mark this item as failed so that the button gets disabled
          setFailedItems(prev => ({ ...prev, [itemId]: true }));
        });
      };
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2, width: '90%', maxWidth: 'none' }}>
            <Paper elevation={1} sx={{ width: '90%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Paper elevation={1} sx={{ width: '90%', p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Paper elevation={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box>
                            <Stack direction='row' spacing={4}>
                                <Card>
                                    <Stack direction='column' spacing={4} alignItems='center'>
                                        <Typography>Item Count</Typography>
                                        <Typography>{session?.itemCount}</Typography>
                                    </Stack>
                                </Card>
                                <Card>
                                    <Stack direction='column' spacing={4} alignItems='center'>
                                        <Typography>Character Count</Typography>
                                        <Typography>{session?.characterCount}</Typography>
                                    </Stack>
                                </Card>
                                <Card>
                                    <Stack direction='column' spacing={4} alignItems='center'>
                                        <Typography>Created At</Typography>
                                        <Typography>{session?.createAtUtc?.toLocaleString()}</Typography>
                                    </Stack>
                                </Card>
                            </Stack>
                        </Box>
                    </Paper>
                    <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', gap: 20, flexWrap: 'wrap' }}>
                        <Paper elevation={6} sx={{ width: 200, height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px dashed grey' }}>
                            <Button
                                variant="contained"
                                disabled={session === null}
                                onClick={() => ExportSessionItemViewToCsv(session?.items)}
                                startIcon={<CloudUploadIcon />}
                            >
                                Export
                            </Button>
                        </Paper>
                    </Box>
                </Paper>

                <SessionNameComponent
                    isEditing={isEditingSessionName}
                    sessionName={sessionName}
                    setSessionName={setSessionName}
                    handleSave={handleSaveSessionName}
                    handleEdit={handleEditSessionName}
                />
                <SessionGroupComponent
                    sessionId={sessionId}
                />
                <Paper elevation={6} sx={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, mt: 2, p: 2 }}>

                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell>Text</TableCell>
                    <TableCell>Play</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {session && session.items.map((item) => {
                    const hasAudio = item.audioUrl && item.audioUrl.trim().length > 0;
                    const isDisabled = !hasAudio || failedItems[item.id] === true;
                    return (
                        <TableRow key={item.id}>
                        <TableCell>{item.fileName}</TableCell>
                        <TableCell>{item.transcription}</TableCell>
                        <TableCell>
                        <Button
                            onClick={() => !isDisabled && handlePlayAudio(item.id, item.audioUrl)}
                            startIcon={<PlayArrowIcon />}
                            disabled={isDisabled}
                            >
                            Play
                            </Button>
                        </TableCell>
                        </TableRow>
                    );
                    })}
                </TableBody>
                </Table>

                </Paper>
            </Paper>
        </Box>
    );
};

export default Log;
