// src/context/UserContext.tsx
import React, { createContext, useState, ReactNode, useEffect, useCallback } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react'; // Import MSAL hooks
import { useLDClient } from 'launchdarkly-react-client-sdk';
interface User {
  key: string;
  // add other user properties as needed
}

interface UserContextType {
  user: User;
  setUser?: (user: User) => void;
}

const defaultUser: User = { key: 'anonymous-user' };

export const UserContext = createContext<UserContextType>({
  user: defaultUser
});

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User>(defaultUser);
  const { accounts } = useMsal(); // Get MSAL accounts
  const isAuthenticated = useIsAuthenticated(); // Check if the user is authenticated
  const ldClient = useLDClient(); // Get LaunchDarkly client
  const setUserCallback = useCallback((user: User) => {
    setUser(user);
  }, []);

  const identifyUser = useCallback(() => {
    if (!ldClient) return;
  
    if (isAuthenticated && accounts.length > 0) {
      const username = accounts[0].username;
      setUser({ key: username });
      ldClient.identify({ key: username });
    } else {
      const anonymousUser = 'anonymous-user';
      setUser({ key: anonymousUser });
      ldClient.identify({ key: anonymousUser });
    }
  }, [isAuthenticated, accounts, ldClient]);
  
  useEffect(() => {
    identifyUser();
  }, [identifyUser]);


  return (
    <UserContext.Provider value={{ user, setUser: setUserCallback }}>
      {children}
    </UserContext.Provider>
  );
};
