import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './msalConfig';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { UserProvider } from './UserProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const ldClientSideID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID;
if (!ldClientSideID) {
  console.warn("LaunchDarkly client ID is not defined");
}

const appInsightsConnectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING;
if (!appInsightsConnectionString) {
  console.warn("Application Insights connection string is not defined");
}
// Application Insights configuration
const browserHistory = createBrowserHistory();
const  reactPlugin = new ReactPlugin();
const  appInsights = new ApplicationInsights({
  config: {
    connectionString: appInsightsConnectionString,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});
appInsights.loadAppInsights();

// MSAL Instance
export const msalInstance = new PublicClientApplication(msalConfig);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldClientSideID ?? '',
    options: { bootstrap: 'localStorage' },
  });

  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <LDProvider>
            <UserProvider>
              <App />
            </UserProvider>
          </LDProvider>
        </MsalProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
})();