import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Typography } from '@mui/material';
import { MemberInfo } from '../../ProjectAlignmentService';

interface MemberTableProps {
  members: MemberInfo[];
  onEditMemberClick: (member: MemberInfo) => void;
  onReviewClick: (member: MemberInfo) => void;
  onNewMemberClick: () => void;
}

const MemberTable: React.FC<MemberTableProps> = ({ members, onEditMemberClick, onReviewClick, onNewMemberClick }) => {
  return (
    <Box sx={{ mt: 4, width: '100%' }}>
      <Typography variant='h5' gutterBottom>
        Project Members
      </Typography>
      <Button variant="contained" color="primary" onClick={onNewMemberClick} sx={{ mb: 2 }}>
        Add Member
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="member table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Member ID</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Contact Type</TableCell>
              <TableCell align="center">Contact Value</TableCell>
              <TableCell align="center">Special Instructions</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member) => (
              <TableRow key={member.memberId} hover>
                <TableCell align="center">{member.memberId}</TableCell>
                <TableCell align="center">{member.name}</TableCell>
                <TableCell align="center">{member.contactType}</TableCell>
                <TableCell align="center">{member.contactValue}</TableCell>
                <TableCell align="center">{member.specialInstructions}</TableCell>
                <TableCell align="center">
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Button variant="contained" color="primary" onClick={() => onReviewClick(member)}>
                      Review
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => onEditMemberClick(member)}>
                      Edit
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MemberTable;
