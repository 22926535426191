import React, { useState } from 'react';
import { Box, Paper, TextField, Button, Typography, Grid } from '@mui/material';
import { UpdateProjectRequest, ProjectDto } from '../../ProjectAlignmentService';
import ProjectStateIndicators from './ProjectStateIndicators';
import MessageTemplateInstructions from './MessageTemplateInstructions';

interface ProjectDetailsProps {
  editProject: UpdateProjectRequest;
  isEditing: boolean;
  isAdding: boolean;
  selectedProject: ProjectDto | null;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSaveChanges: () => void;
  onEditClick: () => void;
  onDeleteProject: () => void;
  onCancelClick: () => void;
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  editProject,
  isEditing,
  isAdding,
  selectedProject,
  onInputChange,
  onSaveChanges,
  onEditClick,
  onDeleteProject,
  onCancelClick,
}) => {
  const [nameError, setNameError] = useState<string | null>(null);

  const handleSaveChanges = () => {
    if (!editProject.name || !editProject.name.trim()) {
      setNameError('Name is required.');
      return;
    }
    setNameError(null);
    onSaveChanges();
  };

  return (
    <Box sx={{ mt: 4, width: '100%' }}>
      <Typography variant='h5' gutterBottom>
        {isAdding ? 'Add New Project' : 'Project Details'}
      </Typography>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Grid container spacing={2}>
         
          <Grid item xs={12}>
            <TextField
              label="Name"
              name="name"
              value={editProject.name}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: !isEditing }}
              error={!!nameError}
              helperText={nameError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={editProject.description}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: !isEditing }}
            />
          </Grid>
          <Grid item xs={12}>
            <MessageTemplateInstructions />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message From"
              name="messageFrom"
              value={editProject.messageFrom}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: !isEditing }}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message Subject"
              name="messageSubject"
              value={editProject.messageSubject}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: !isEditing }}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message Template"
              name="messageTemplate"
              value={editProject.messageTemplate}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: !isEditing }}
              multiline
            />
          </Grid>
          {selectedProject && (
            <Grid item xs={12}>
              <TextField
                label="System Note"
                name="systemNote"
                value={selectedProject.systemNote}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
                multiline
              />
            </Grid>
          )}
          {selectedProject && (
            <Grid item xs={12}>
              <ProjectStateIndicators
                projectState={selectedProject.projectState}
                messageTemplateState={selectedProject.messageTemplateState}
                memberState={selectedProject.memberState}
              />
            </Grid>
          )}
        </Grid>
        {!isEditing ? (
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button variant="contained" color="primary" onClick={onEditClick}>
              Edit
            </Button>
            <Button variant="contained" color="secondary" onClick={onDeleteProject}>
              Delete
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
            <Button variant="outlined" color="secondary" onClick={onCancelClick}>
              Cancel
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default ProjectDetails;
