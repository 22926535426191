import React from 'react';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import { FileItem } from '../Types';


function FilesTableComponent({ files, sortFiles, handleSort, handlePlayAudio }:
    { files: FileItem[], 
      sortFiles: (files: FileItem[]) => FileItem[], 
      handleSort: (key: string) => void, 
      handlePlayAudio: (file: File) => void }
) {
    return (
        <Paper elevation={6} sx={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, mt: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell onClick={() => handleSort('name')}>File Name</TableCell>
                        <TableCell onClick={() => handleSort('text')}>Text</TableCell>
                        <TableCell>Play</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortFiles([...files]).map((file, index) => (
                        <TableRow key={index}>
                            <TableCell>{file.name}</TableCell>
                            <TableCell>{file.text}</TableCell>
                            <TableCell><Button onClick={() => handlePlayAudio(file.file)} startIcon={<PlayArrowIcon />}>Play</Button></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default FilesTableComponent;
