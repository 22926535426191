import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, CircularProgress } from '@mui/material';
import { ProjectAlignmentService, TranscriptDto,  UpdateMemberRequest, MemberInfo } from '../../ProjectAlignmentService';
import SignalRService from '../../SignalRService';

interface TranscriptReviewDialogProps {
  projectId: string;
  memberId: string;
  open: boolean;
  onClose: () => void;
}

interface UserProjectMemberTranscriptMessageData { 
  ProjectId: string;
  MemberId: string;
  TranscriptId: string;
  MessageId: string;
}

const projectService = new ProjectAlignmentService();

const TranscriptReviewDialog: React.FC<TranscriptReviewDialogProps> = ({ projectId, memberId, open, onClose }) => {
  const [transcript, setTranscript] = useState<TranscriptDto | null>(null);
  const [member, setMember] = useState<MemberInfo | null>(null);
  const [specialInstructions, setSpecialInstructions] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const signalRService = SignalRService.getInstance('ProjectAlignment');
    const handleTranscriptMessageUpdated = async (data: UserProjectMemberTranscriptMessageData) => {
      if (data.ProjectId === projectId && data.MemberId === memberId) {
        const updatedTranscript = await projectService.getTranscriptByProjectMember(projectId, memberId);
        if (updatedTranscript.messages.length > 0 && updatedTranscript.messages[updatedTranscript.messages.length - 1].role === 'system') {
          updatedTranscript.messages.push({
            id: 'temp',
            role: 'member',
            text: '...',
            summary: null,
            createAtUtc: new Date(),
            sendState: { sentAtUtc: null, deliveryStatus: 'pending', errorMessage: null }
          });
        }

        setTranscript(updatedTranscript);
        setIsSaving(false); 
      }
    };
    signalRService.on('project-member-transcript-message-updated', handleTranscriptMessageUpdated);

    return () => {
      signalRService.off('project-member-transcript-message-updated', handleTranscriptMessageUpdated);
    };
  }, [transcript, memberId, projectId]);

  useEffect(() => {
    const fetchTranscripts = async () => {
      try {
        const data = await projectService.getTranscriptByProjectMember(projectId, memberId);

        if (data.messages.length > 0 && data.messages[data.messages.length - 1].role === 'system') {
          data.messages.push({
            id: 'temp',
            role: 'member',
            text: '...',
            summary: null,
            createAtUtc: new Date(),
            sendState: { sentAtUtc: null, deliveryStatus: 'pending', errorMessage: null }
          });
        }

        setTranscript(data);
      } catch (error) {
        console.error('Error fetching transcripts', error);
      }
    };

    const fetchMemberInfo = async () => {
      try {
        const memberData = await projectService.getMember(projectId, memberId);
        setMember(memberData);
        setSpecialInstructions(memberData.specialInstructions || '');
      } catch (error) {
        console.error('Error fetching member information', error);
      }
    };

    if (open) {
      fetchTranscripts();
      fetchMemberInfo();
    }
  }, [projectId, memberId, open]);

  const handleSendClick = async (transcriptId: string) => {
    await projectService.sendTranscript(projectId, memberId, transcriptId);
  };

  const handleSaveSpecialInstructions = async () => {
    if (!member) return;
    const updateMemberDto: UpdateMemberRequest = {
      name: member.name,
      contactType: member.contactType,
      contactValue: member.contactValue,
      specialInstructions,
    };

    setIsSaving(true); // Start loading

    try {
      await projectService.updateMember(projectId, memberId, updateMemberDto);
    } catch (error) {
      console.error('Error updating member', error);
      setIsSaving(false); // End loading
    } 
  };
  const formatDate = (date: Date) => {
    return new Date(date).toLocaleString();
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Transcript Review</DialogTitle>
      <DialogContent>
        
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
          <Typography variant='h6'>Special Instructions</Typography>
          <TextField
            margin="dense"
            name="specialInstructions"
            label="Special Instructions"
            type="text"
            fullWidth
            variant="standard"
            value={specialInstructions}
            onChange={(e) => setSpecialInstructions(e.target.value)}
            disabled={!transcript?.canEdit || isSaving}
          />
          {transcript?.canEdit && (
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleSaveSpecialInstructions}
              disabled={isSaving} // Disable button while saving
            >
              {isSaving ? <CircularProgress size={24} /> : 'Save'} 
            </Button>
          )}
        </Paper>
        <Paper elevation={3} 
               sx={{ p: 2, 
                     mb: 3,
                     whiteSpace: 'pre-wrap'
                     }}>
        <Typography variant='h6'>Summary</Typography>
        <Typography variant='body1'>{transcript?.summary || ''}</Typography>
        
        </Paper>
        {transcript && (
          <Paper elevation={3} sx={{ p: 2 }}>
            {transcript.messages.map((message) => (
              <Paper
                key={message.id}
                sx={{
                  p: 2,
                  mb: 1,
                  backgroundColor: message.role === 'system' ? '#ADD8E6' : '#00008B',
                  color: message.role === 'system' ? 'black' : 'white',
                  marginLeft: message.role === 'system' ? '0' : 'auto',
                  marginRight: message.role === 'system' ? 'auto' : '0',
                  maxWidth: '75%',
                  whiteSpace: 'pre-wrap'
                }}
              >
                <Typography variant='body1' color={message.role === 'system' ? 'textSecondary' : 'primary'}>
                  {message.role === 'system' ? 'System' : 'Member'}
                </Typography>
                <Typography variant='body2'>{message.text}</Typography>
                {message.role === 'system' && (
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
                    {message.id !== 'temp' && message.sendState.deliveryStatus === 'pending' &&   (
                      <Button variant="contained" color="primary" onClick={() => handleSendClick(message.id)}>
                        Send Message
                      </Button>
                    )}
                     {message.sendState.sentAtUtc && (
                      <Typography variant='caption' color='textSecondary'>
                        Sent at: {formatDate(message.sendState.sentAtUtc)}
                      </Typography>
                    )}
                  </Box>
                )}
                  {message.role === 'member' && (
                   <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
                      <Typography variant='caption' color='textSecondary'>
                        Recived at: {formatDate(message.createAtUtc)}
                      </Typography>
                   </Box>
                  )}
              </Paper>
            ))}
          </Paper>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TranscriptReviewDialog;
