import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

const MessageTemplateInstructions: React.FC = () => (
  <>
    <Typography variant="subtitle1">Message Template Instructions:</Typography>
    <Typography variant="body2" gutterBottom>
      To create a good message template, you can use the following parameters:
    </Typography>
    <List dense>
      <ListItem>
        <ListItemText primary="[name] - Inserts the member's name into the message" />
      </ListItem>
      {/* Add more parameters here as needed */}
    </List>
  </>
);

export default MessageTemplateInstructions;
