import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Home from "./Pages/Home";
import { LayoutWrapper } from './Components/PageLayout';
import { useMsal } from '@azure/msal-react';
import { LandingPage } from './Pages/Landing';
import Logs from './Pages/Logs';
import Log from './Pages/Log';
import Usage from './Pages/Usage';
import ProjectList from './Pages/ProjectAgliment';
import { useFlags } from 'launchdarkly-react-client-sdk'; // Import useFlags from LaunchDarkly
// import useTokenRefreshHandler from './Hooks/UseTokenRefreshHandler';

type AppProps = {};

const App: React.FC<AppProps> = () => {
  // useTokenRefreshHandler(); // Add the token refresh handler

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<LayoutWrapper />}>
            {/* Check authentication status for root path */}
            <Route path="/" element={<AuthCheck redirectTo="/home" />}>
              <Route index element={<LandingPage />} />
            </Route>

            {/* Protected Home route */}
            <Route element={<ProtectedRoute />}>
              <Route path="/home" element={<Home />} />
              <Route path="/logs" element={<Logs />} />
              <Route path="/log/:sessionId" element={<Log />} />
              <Route path="/usage" element={<Usage />} />

              {/* Conditional Project List route based on feature flag */}
              <Route element={<FeatureFlagRoute flagKey="projects" />}>
                <Route path="/projects" element={<ProjectList />} />
              </Route>

            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

// AuthCheck component to redirect authenticated users from LandingPage to Home
const AuthCheck = ({ redirectTo }: { redirectTo: string }) => {
  const { accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;

  // If authenticated, redirect to the given path, otherwise render the Outlet (children routes)
  return isAuthenticated ? <Navigate to={redirectTo} /> : <Outlet />;
};

// ProtectedRoute component to check authentication status
const ProtectedRoute = () => {
  const { accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;

  // If authenticated, render the Outlet (children routes)
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

// FeatureFlagRoute component to conditionally render routes based on feature flag
const FeatureFlagRoute = ({ flagKey }: { flagKey: string }) => {
  const flags = useFlags();
  const isEnabled = flags[flagKey]; // Check if the flag is enabled

  return isEnabled ? <Outlet /> : <Navigate to="/home" />; // Redirect to home if flag is disabled
};

// import { withLDConsumer } from 'launchdarkly-react-client-sdk';
// export default withLDConsumer()(App);
export default App;
