import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosInstance } from 'axios';
import { loginRequest, msalConfig } from './msalConfig';


export interface ProjectDto {
    projectId: string ;
    name: string | null;
    description: string | null;
    messageFrom: string | null;
    messageSubject: string | null;
    messageTemplate: string | null;
    systemNote: string | null;
    projectState: string | null;
    messageTemplateState: string | null;
    memberState: string | null;
    summary: string | null;
    createAtUtc: Date;
    statuses: StatusInfo[] | null;
    members: MemberInfo[] | null;
}

export interface ProjectInfo {
    projectId: string;
    name: string;
    description: string | null;
    createAtUtc: Date;
}


export interface StatusInfo {
    status: string | null;
    createdAtUtc: Date;
}

export interface MemberInfo {
    memberId: string ;
    name: string ;
    contactType: string | null;
    contactValue: string | null;
    specialInstructions: string | null;
}

export interface ContactInfo {
    contactType: string | null;
    contactValue: string | null;
}


export interface TranscriptDto {
    transcriptId: string;
    projectId: string ;
    memberId: string ;
    contactInfo: ContactInfo;
    summary: string | null;
    canEdit: boolean;
    isFinished: boolean;
    statuses: StatusInfo[] ;
    messages: TranscriptMessageDto[] ;
}


export interface MessageSendStateDto {
    sentAtUtc: Date | null;
    deliveryStatus:  string | null;
    errorMessage: string | null;
}
export interface TranscriptMessageDto {
    id: string ;
    role: string ;
    text: string | null;
    summary: string | null;
    createAtUtc: Date;
    sendState: MessageSendStateDto;
}

export interface AddMemberRequest {
    name: string | null;
    contactType: string | null;
    contactValue: string | null;
    specialInstructions: string | null;
}

export interface UpdateProjectRequest {
    name: string | null;
    description: string | null;
    messageFrom: string | null;
    messageSubject: string | null;
    messageTemplate: string | null;
}
export interface CreateProjectRequest {
    name: string | null;
    description: string | null;
    messageFrom: string | null;
    messageSubject: string | null;
    messageTemplate: string | null;
}


export interface UpdateMemberRequest {
    name: string | null;
    contactType: string | null;
    contactValue: string | null;
    specialInstructions: string | null;
}

export interface UpdateTranscriptRequest {
    transcriptId: string ;
    message: string;
}

export class ProjectAlignmentService {
    private axiosInstance: AxiosInstance;
    private msalInstance: PublicClientApplication;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        });
        this.msalInstance = new PublicClientApplication(msalConfig);
        this.setupAxiosInterceptors();
    }

    private setupAxiosInterceptors() {
        this.axiosInstance.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;
                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    await this.refreshToken();
                    originalRequest.headers['Authorization'] = this.axiosInstance.defaults.headers.common['Authorization'];
                    return this.axiosInstance(originalRequest);
                }
                return Promise.reject(error);
            }
        );
    }

    private async refreshToken() {
        const account = this.msalInstance.getAllAccounts()[0];
        const tokenRequest = {
            account,
            scopes: loginRequest.scopes
        };

        try {
            const response = await this.msalInstance.acquireTokenSilent(tokenRequest);
            const accessToken = response.accessToken;
            this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                await this.msalInstance.acquireTokenRedirect(tokenRequest);
            }
            throw error;
        }
    }

    private async getAccessToken() {
        if (this.axiosInstance.defaults.headers.common['Authorization']) {
            return;
        }

        await this.msalInstance.initialize();
        const account = this.msalInstance.getAllAccounts()[0];
        const tokenRequest = {
            account,
            scopes: loginRequest.scopes // Replace with your API scopes
        };

        try {
            const response = await this.msalInstance.acquireTokenSilent(tokenRequest);
            const accessToken = response.accessToken;
            this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                await this.msalInstance.acquireTokenRedirect(tokenRequest);
            }
            throw error;
        }
    }

    public async createProject(createProjectDto: CreateProjectRequest): Promise<void> {
        await this.getAccessToken();
        await this.axiosInstance.post('/api/ProjectAlignment/Project', createProjectDto);
    }

    public async getProjects(): Promise<ProjectInfo[]> {
        await this.getAccessToken();
        const response = await this.axiosInstance.get<ProjectInfo[]>('/api/ProjectAlignment/Projects');
        return response.data.map(project => ({
            ...project,
            createAtUtc: new Date(project.createAtUtc)
        }));
    }

    public async getProject(projectId: string): Promise<ProjectDto> {
        await this.getAccessToken();
        const response = await this.axiosInstance.get<ProjectDto>(`/api/ProjectAlignment/Project/${projectId}`);
        return response.data;
    }

    public async deleteProject(projectId: string): Promise<void> {
        await this.getAccessToken();
        await this.axiosInstance.delete(`/api/ProjectAlignment/Project/${projectId}`);
    }

    public async updateProject(projectId: string, updateProjectDto: UpdateProjectRequest): Promise<void> {
        await this.getAccessToken();
        await this.axiosInstance.post(`/api/ProjectAlignment/Project/${projectId}`, updateProjectDto);
    }

    public async getProjectMembers(projectId: string): Promise<MemberInfo[]> {
        await this.getAccessToken();
        const response = await this.axiosInstance.get<MemberInfo[]>(`/api/ProjectAlignment/Project/${projectId}/Members`);
        return response.data;
    }

    public async addProjectMembers(projectId: string, addMemberDtos: AddMemberRequest[]): Promise<void> {
        await this.getAccessToken();
        await this.axiosInstance.post(`/api/ProjectAlignment/Project/${projectId}/Members`, addMemberDtos);
    }

    public async getMember(projectId: string, memberId: string): Promise<MemberInfo> {
        await this.getAccessToken();
        const response = await this.axiosInstance.get<MemberInfo>(`/api/ProjectAlignment/Project/${projectId}/Member/${memberId}`);
        return response.data;
    }

    public async deleteMember(projectId: string, memberId: string): Promise<void> {
        await this.getAccessToken();
        await this.axiosInstance.delete(`/api/ProjectAlignment/Project/${projectId}/Member/${memberId}`);
    }

    public async updateMember(projectId: string, memberId: string, updateMemberDto: UpdateMemberRequest): Promise<void> {
        await this.getAccessToken();
        await this.axiosInstance.post(`/api/ProjectAlignment/Project/${projectId}/Member/${memberId}`, updateMemberDto);
    }

    public async getTranscriptByProjectMember(projectId: string, memberId: string): Promise<TranscriptDto> {
        await this.getAccessToken();
        const response = await this.axiosInstance.get<TranscriptDto>(`/api/ProjectAlignment/Project/${projectId}/Member/${memberId}/Transcript`);
        return response.data;
    }

    public async getTranscriptById(projectId: string, memberId: string, transcriptId: string): Promise<TranscriptDto> {
        await this.getAccessToken();
        const response = await this.axiosInstance.get<TranscriptDto>(`/api/ProjectAlignment/Project/${projectId}/Member/${memberId}/Transcript/${transcriptId}`);
        return response.data;
    }

    public async updateTranscript(projectId: string, memberId: string, transcriptId: string, updateTranscriptDto: UpdateTranscriptRequest): Promise<void> {
        await this.getAccessToken();
        await this.axiosInstance.put(`/api/ProjectAlignment/Project/${projectId}/Member/${memberId}/Transcript/${transcriptId}`, updateTranscriptDto);
    }

    public async sendTranscript(projectId: string, memberId: string, transcriptId: string): Promise<void> {
        await this.getAccessToken();
        await this.axiosInstance.post(`/api/ProjectAlignment/Project/${projectId}/Member/${memberId}/Transcript/${transcriptId}/Send`);
    }

    public async sendAllTranscripts(projectId: string): Promise<void> {
        await this.getAccessToken();
        await this.axiosInstance.post(`/api/ProjectAlignment/Project/${projectId}/SendAll`);
    }
}