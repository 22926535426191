import React from 'react';
import { Box, Chip, Typography } from '@mui/material';

// interface StateIndicatorProps {
//   state: string | null;
// }

const getProjectStateIndicator = (state: string | null) => {
  switch (state) {
    case 'not_ready':
      return <Chip label="Not Ready" color="error" />;
    case 'ready':
      return <Chip label="Ready" color="success" />;
    case 'running':
      return <Chip label="Running" color="primary" />;
    case 'completed':
      return <Chip label="Completed" color="default" />;
    default:
      return <Chip label="Unknown State" />;
  }
};

const getMessageTemplateStateIndicator = (state: string | null) => {
  switch (state) {
    case 'needs_update':
      return <Chip label="Needs Update" color="error" />;
    case 'validating':
      return <Chip label="Validating" color="warning" />;
    case 'valid':
      return <Chip label="Valid" color="success" />;
    case 'invalid':
      return <Chip label="Invalid" color="error" />;
    default:
      return <Chip label="Unknown State" />;
  }
};

const getMemberStateIndicator = (state: string | null) => {
  switch (state) {
    case 'no_members':
      return <Chip label="No Members" color="error" />;
    case 'members_set':
      return <Chip label="Members Set" color="success" />;
    default:
      return <Chip label="Unknown State" />;
  }
};

interface ProjectStateIndicatorsProps {
  projectState: string | null;
  messageTemplateState: string | null;
  memberState: string | null;
}

const ProjectStateIndicators: React.FC<ProjectStateIndicatorsProps> = ({
  projectState,
  messageTemplateState,
  memberState,
}) => (
  <Box display="flex" gap={2}>
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="caption">Project Status</Typography>
      {getProjectStateIndicator(projectState)}
    </Box>
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="caption">Message Template Status</Typography>
      {getMessageTemplateStateIndicator(messageTemplateState)}
    </Box>
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="caption">Member Status</Typography>
      {getMemberStateIndicator(memberState)}
    </Box>
  </Box>
);

export default ProjectStateIndicators;
